window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    
    require('bootstrap/js/dist/index');
    require('bootstrap/js/dist/util');
    require('bootstrap/js/dist/button');
    require('bootstrap/js/dist/alert');
    require('bootstrap/js/dist/collapse');
    require('bootstrap/js/dist/modal');
    //require('bootstrap');
} catch (e) {}