$(document).ready(function() {
	$('.nav-link').on('click', function() {
	    document.title = 'Domofruits - ' + $(this).find('.onglet').text();
	    $('.nav-item').removeClass('active');
	    $(this).parent().addClass('active');
	    $('.nav-item .sr-only').empty();
	    $(this).children('.sr-only').append(' (courant)');
	});

	$('a[href^="#"]').click(function(){
		var ancreInterne = $(this).attr("href");
		$('html, body').animate({
			scrollTop: $(ancreInterne).offset().top - 40
		}, 'slow');
		return false;
	});

	$('.nav-link').on('click', function() {
		$('#navbarResponsive').collapse('hide');
	});
});